import {ApolloClient} from '@apollo/client';
import type {BroadcastFn} from '@backstage-components/base';
import {assertEvent, assign, setup} from 'xstate';
import {
  AccessCodeInstruction,
  AccessCodeInstructionSchema,
} from './AccessCodeDefinition';
import {
  ResendAccessCode,
  ResendAccessCodeMutation,
  ResendAccessCodeVariables,
} from './gql';

/**
 * @private exported for tests
 */
export interface ViewContext {
  broadcast: BroadcastFn<typeof AccessCodeInstructionSchema>;
  client: ApolloClient<object>;
  /**
   * The error message if there is one. Allows `undefined` or `null` as empty
   * value because XState's `assign` treats `undefined` like "no update".
   */
  error?: string | null;
  code?: string | null;
  /** The version identifier for the site */
  siteVersionId: string | undefined;
}

export const AccessCodeViewMachine = setup({
  types: {} as {
    context: ViewContext;
    events: AccessCodeInstruction;
    input: Pick<ViewContext, 'broadcast' | 'client' | 'siteVersionId'>;
  },
  actions: {
    clearError: assign({error: () => null}),
    resendAccessCode: async ({context, event}) => {
      assertEvent(event, 'AccessCode:resend');
      try {
        const result = await context.client.mutate<
          ResendAccessCode,
          ResendAccessCodeVariables
        >({
          mutation: ResendAccessCodeMutation,
          variables: {
            data: {
              coreId: event.meta.coreId,
              email: event.meta.email,
              showId: event.meta.showId,
              siteVersionId: context.siteVersionId,
            },
          },
        });
        const response = result.data?.triggerResendAccessCode;
        if (response && 'id' in response) {
          return context.broadcast({
            type: 'AccessCode:on-resend-success',
            meta: {},
          });
        } else {
          return context.broadcast({
            type: 'AccessCode:on-resend-failure',
            meta: {
              reason: response ? response.message : 'No response from API',
              code: response?.code,
            },
          });
        }
      } catch (err) {
        return context.broadcast({
          type: 'AccessCode:on-resend-failure',
          meta: {
            reason: err instanceof Error ? err.message : JSON.stringify(err),
          },
        });
      }
    },
  },
}).createMachine({
  id: 'AccessCodeResend',
  initial: 'login',
  context: ({input}) => ({
    broadcast: input.broadcast,
    client: input.client,
    siteVersionId: input.siteVersionId,
  }),
  states: {
    login: {
      on: {
        'AccessCode:resend-view': {target: 'resend', actions: ['clearError']},
        'AccessCode:verify': {
          target: 'loginPending',
          actions: ['clearError'],
        },
      },
    },
    loginPending: {
      on: {
        'AccessCode:on-failure': {
          target: 'login',
          actions: [assign({error: ({event}) => event.meta.reason})],
        },
        'AccessCode:on-success': {target: 'success'},
      },
    },
    resend: {
      on: {
        'AccessCode:login-view': {target: 'login', actions: ['clearError']},
        'AccessCode:resend': {
          target: 'resendPending',
          actions: ['clearError', 'resendAccessCode'],
        },
      },
    },
    resendPending: {
      on: {
        'AccessCode:on-resend-failure': {
          target: 'resend',
          actions: [
            assign({
              error: ({event}) => event.meta.reason,
              code: ({event}) => event.meta.code,
            }),
          ],
        },
        'AccessCode:on-resend-success': {target: 'resendSuccess'},
      },
    },
    resendSuccess: {
      on: {
        'AccessCode:resend-view': {target: 'resend', actions: ['clearError']},
        'AccessCode:verify': {target: 'loginPending'},
      },
    },
    success: {
      type: 'final',
    },
  },
});
